import LocalStorage from "@/core/utils/LocalStorage";

type DeviceType = "video" | "audio";
export interface Device {
  id: string;
  name: string;
}

export const selectDevice = (id: string, type: DeviceType = "audio") => {
  if (type === "audio") LocalStorage.setInputDevice(id);
  else LocalStorage.setVideoInputDevice(id);
};

export const getInputDevice = (type: DeviceType = "audio") => {
  return type === "audio"
    ? LocalStorage.getInputDevice()
    : LocalStorage.getVideoInputDevice();
};

export const getDevices = async (
  type: DeviceType = "audio",
): Promise<Device[]> => {
  if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
    console.log("enumerateDevices() not supported.");
    return [];
  }

  const inputDevice = getInputDevice();
  const constraints: MediaStreamConstraints =
    type === "audio"
      ? {
          video: false,
          audio: {
            channelCount: 1,
            echoCancellation: false,
            deviceId: inputDevice || undefined,
          },
        }
      : {
          audio: false,
          video: {
            deviceId: inputDevice || undefined,
          },
        };

  try {
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    stream.getTracks().forEach(t => t.stop());
  } catch (error) {
    console.warn(
      `Warning: Access to ${
        type === "audio" ? "microphone" : "camera"
      } denied.`,
      error,
    );
  }

  try {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const deviceType: MediaDeviceKind =
      type === "audio" ? "audioinput" : "videoinput";

    const inputDevices = devices
      .filter(device => device.kind === deviceType)
      .map(device => ({
        id: device.deviceId,
        name: device.label || `Unknown device (${type})`,
      }));

    if (!inputDevice && inputDevices.length > 0)
      selectDevice(inputDevices[0].id, type);

    console.log(`Found devices (${type}):`, inputDevices);

    return inputDevices;
  } catch (error) {
    console.error("Error while listing devices:", error);
    return [];
  }
};
