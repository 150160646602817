import RecordRTC, { MediaStreamRecorder } from "recordrtc";
import i18n from "@/core/plugins/i18n";
import { getInputDevice } from "@/views/recorder/lib/devices";

interface StartOptions {
  onError?: (errorMessage: string) => void;
}
class VideoRecorder {
  stream: MediaStream | undefined;
  videoRecorder: RecordRTC | undefined;
  audioRecorder: RecordRTC | undefined;

  muted = false;
  isRecording: boolean;
  isPaused: boolean;

  constructor() {
    this.isRecording = false;
    this.isPaused = false;
  }

  async start(options?: StartOptions, includeVideo: boolean = false) {
    if (this.isRecording || this.isPaused)
      throw new Error("Can't start when already recording or paused.");

    // Check for camera and/or microphone permissions
    try {
      await this.checkCameraAndMicrophonePermissions(includeVideo);
    } catch (error) {
      console.error("Error checking recording device permissions:", error);
      if (options?.onError)
        if (includeVideo)
          // Pass translated message for snackbar
          options.onError(i18n.t("recording.noDevicePermission").toString());
        else options.onError(i18n.t("recording.noDevicePermission").toString());

      throw error;
    }

    // Obtain stream for video and/or audio also set min and max for video resolution
    const constraints: MediaStreamConstraints = {
      audio: { deviceId: getInputDevice("audio") },
      video: includeVideo && {
        deviceId: getInputDevice("video"),
        width: { ideal: 640, min: 320, max: 1280 },
        height: { ideal: 480, min: 240, max: 720 },
      },
    };

    // Start recording with the obtained stream
    try {
      const [audioStream, videoStream] = await Promise.all([
        navigator.mediaDevices.getUserMedia({ audio: constraints.audio }),
        includeVideo
          ? navigator.mediaDevices.getUserMedia({ video: constraints.video })
          : Promise.resolve(undefined),
      ]);

      const stream = new MediaStream([
        ...audioStream.getAudioTracks(),
        ...(videoStream ? videoStream.getVideoTracks() : []),
      ]);

      // Configure video and audio options
      const optsVideo: RecordRTC.Options = {
        recorderType: MediaStreamRecorder,
        mimeType: "video/mp4",
        type: "video",
        videoBitsPerSecond: 1000000, // Limit bitrate to decrease video size
      };

      const optsAudio: RecordRTC.Options = {
        recorderType: MediaStreamRecorder,
        mimeType: "audio/wav",
        type: "audio",
        audioBitsPerSecond: 128000, // Limit bitrate to decrease audios size
      };

      // Setup video and audio recorder
      const videoRecorder = includeVideo
        ? new RecordRTC(stream, optsVideo)
        : undefined;
      const audioRecorder = new RecordRTC(stream, optsAudio);

      if (includeVideo) videoRecorder!.startRecording();
      audioRecorder.startRecording();

      this.isRecording = true;
      this.stream = stream;
      this.videoRecorder = videoRecorder;
      this.audioRecorder = audioRecorder;

      // Return the stream object
      return this.stream;
    } catch (error) {
      console.error("Error while start recording: ", error);
      return undefined;
    }
  }

  async checkCameraAndMicrophonePermissions(
    includeVideo: boolean,
  ): Promise<void> {
    try {
      await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: getInputDevice("audio") },
        video: includeVideo && { deviceId: getInputDevice("video") },
      });
    } catch (error) {
      if (includeVideo)
        throw new Error(
          "Camera and microphone permissions are required for recording.",
        );
      else throw new Error("Microphone permission is required for recording.");
    }
  }

  pause() {
    this.videoRecorder?.pauseRecording();
    this.audioRecorder?.pauseRecording();
    this.isPaused = true;
  }
  resume() {
    this.videoRecorder?.resumeRecording();
    this.audioRecorder?.resumeRecording();
    this.isPaused = false;
  }

  stop(): Promise<{ videoBlob?: Blob; audioBlob?: Blob } | undefined> {
    return new Promise<{ videoBlob?: Blob; audioBlob?: Blob } | undefined>(
      res => {
        if (!this.videoRecorder && !this.audioRecorder) {
          res(undefined);
          return;
        }

        const stopVideo = () =>
          new Promise<void>(resolve =>
            this.videoRecorder?.stopRecording(() => resolve()),
          );

        const stopAudio = () =>
          new Promise<void>(resolve =>
            this.audioRecorder?.stopRecording(() => resolve()),
          );

        Promise.all([
          this.videoRecorder ? stopVideo() : Promise.resolve(),
          stopAudio(),
        ])
          .then(() => {
            if (this.stream) this.stream.getTracks().forEach(t => t.stop());

            this.isRecording = false;
            this.isPaused = false;

            const videoBlob = this.videoRecorder?.getBlob();
            const audioBlob = this.audioRecorder?.getBlob();

            this.videoRecorder?.destroy();
            this.audioRecorder?.destroy();

            this.stream = undefined;
            this.videoRecorder = undefined;
            this.audioRecorder = undefined;

            res({ videoBlob, audioBlob });
          })
          .catch(error => {
            console.error("Error stopping recording:", error);
            res(undefined);
          });
      },
    );
  }

  async reset() {
    this.videoRecorder?.reset();
    this.audioRecorder?.reset();
  }

  toggleMuted() {
    if (this.muted) {
      this.muted = false;
      this.stream?.getAudioTracks().forEach(x => (x.enabled = true));
    } else {
      this.stream?.getAudioTracks().forEach(x => (x.enabled = false));
      this.muted = true;
    }
  }

  async save(fileName: string) {
    const videoBlob = this.videoRecorder?.getBlob();
    const audioBlob = this.audioRecorder?.getBlob();

    if (videoBlob) await this.videoRecorder?.save(`v_${fileName}`);

    if (audioBlob) await this.audioRecorder?.save(`a_${fileName}`);
  }
}

export default VideoRecorder;
